<template>
    <div>
        <h4 class="font-weight-bold text-dark mb-6 mt-6" v-if="isShowTitle">
            {{ documentName }}
        </h4>

        <!-- Create Document Section -->
        <div v-if="(!documentData || !documentData.id) && (!isSubmitted && !isCreated)">
            <div v-if="helpText" class="form-text kt-font-regular mb-2">
                {{ helpText }}
            </div>
            <button type="button" class="btn btn-primary mr-3" :disabled="isReadOnly || processingIndicator"
                @click.prevent="disableCreate ? showDisabledMessage() : onCreateDocument()">
                <i class="far fa-plus-square"></i> Create {{ documentName }}
            </button>

            <b-spinner v-if="processingIndicator" class="ml-3 align-middle" variant="primary" label="Please wait...">
            </b-spinner>
        </div>

        <!-- Existing Document Actions -->
        <div v-if="(documentData && documentData.id) || isSubmitted || isCreated">
            <div class="form-group">
                <a target="_blank" class="btn btn-primary ml-0" :href="documentUrl">
                    <i :class="documentIcon"></i>
                    {{ documentActionText }}
                </a>

                <a v-if="downloadEnabled" target="_blank" class="btn btn-primary ml-5" :href="documentData.download_url">
                    <i class="far fa-download fa-md"></i>
                    Download {{ documentName }}
                </a>

                <a v-if="(getBrokerAdviceEmailTemplateFunc || getConfirmationOfPlacementEmailTemplateFunc || getClientDeclarationEmailTemplate) && !isReadOnly && !isSubmitted"
                    class="btn btn-primary ml-7" @click.prevent="handleClick" :class="{ 'disabled': isEmailDisabled }"
                    :aria-disabled="isEmailDisabled" :tabindex="isEmailDisabled ? -1 : 0">
                    <i class="fa fa-envelope fa-md"></i>
                    Email {{ documentName }}
                </a>

                <button v-if="recreateEnabled && !isReadOnly" type="button" class="btn btn-outline-primary ml-7"
                    v-on:click.prevent="onCreateDocument" :disabled="processingIndicator">
                    Recreate {{ documentName }}
                </button>

                <b-spinner v-if="processingIndicator" class="ml-3" variant="primary" label="Please wait...">
                </b-spinner>
            </div>
        </div>

        <!-- Test Template Button - Always Visible -->
        <div v-if="isTestTemplate" class="mt-3">
            <button type="button" class="btn btn-warning" :disabled="isReadOnly || processingIndicator"
                @click.prevent="testTemplateDownload">
                <i class="fas fa-vial"></i> Test New Template
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Document",
    props: {
        documentName: String,
        helpText: String,
        documentData: Object,
        onCreateDocument: Function,
        isReadOnly: Boolean,
        processingIndicator: Boolean,
        downloadEnabled: Boolean,
        recreateEnabled: {
            type: Boolean,
            default: false,
        },
        isShowTitle: {
            type: Boolean,
            default: true,
        },
        isSubmitted: Boolean,
        isCreated: {
            type: Boolean,
            default: false,
        },
        getClientDeclarationEmailTemplate: {
            type: Function,
            required: false
        },
        getBrokerAdviceEmailTemplateFunc: {
            type: Function,
            required: false
        },
        getConfirmationOfPlacementEmailTemplateFunc: {
            type: Function,
            required: false
        },
        isEmailDisabled: {
            type: Boolean,
            default: false
        },
        isEditable: {
            type: Boolean,
            default: false
        },
        disableCreate: {
            type: Boolean,
            default: false
        },
        disableCreateReason: {
            type: String,
            default: ''
        },
        isTestTemplate: {
            type: Boolean,
            default: false
        },
        testTemplateDownload: {
            type: Function,
            required: false
        }
    },
    methods: {
        handleClick() {
            if (this.getClientDeclarationEmailTemplate) {
                this.getClientDeclarationEmailTemplate();
            }
            else if (this.getBrokerAdviceEmailTemplateFunc) {
                this.getBrokerAdviceEmailTemplateFunc();
            }
            else if (this.getConfirmationOfPlacementEmailTemplateFunc) {
                this.getConfirmationOfPlacementEmailTemplateFunc();
            }
        },

        showDisabledMessage() {
            this.$bvModal.msgBoxOk(this.disableCreateReason || 'Action not available', {
                title: 'Action Disabled',
                headerClass: 'bg-warning',
                bodyClass: 'text-dark',
                okVariant: 'warning',
                headerTextVariant: 'light',
                centered: true
            });
        }
    },

    computed: {
        documentActionText() {
            return this.isEditable ? `Edit ${this.documentName}` : `View ${this.documentName}`;
        },
        documentIcon() {
            return this.isEditable ? 'far fa-edit' : 'far fa-eye';
        },
        documentUrl() {
            if (this.isEditable && this.documentData.edit_url) {
                return this.documentData.edit_url;
            }
            return this.documentData.view_url;
        },
    },
};
</script>
<style scoped>
.btn.disabled {
    opacity: 0.65;
    pointer-events: none;
}
</style>
